import type { CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import { stringify } from 'qs';
import { Notify } from 'quasar';

import { useAuthStore } from '$common/modules/auth/stores/auth.ts';

const config: CreateAxiosDefaults = {
  withCredentials: true,
  paramsSerializer: (params) => stringify(params),
  headers: {
    common: {
      ['X-Requested-With']: 'XMLHttpRequest',
    },
  },
};

export interface QueryParams {
  include?: string[];
  fields?: string[];
  sort?: string[];
  select?: string[];
  filter?: (`${string}` | `-${string}`)[];
}

const separator = ',';

const paramFormatter = (params: QueryParams) => {
  return Object.keys(params).reduce(
    (acc, paramsName) => ({
      ...acc,
      // @ts-expect-error quasar QueryParams is not properly typed as array-like
      [paramsName]: params[paramsName].join(separator),
    }),
    {},
  );
};

const client = axios.create(config);

client.interceptors.response.use(
  (response) => response,
  async function (error) {
    // Unauthorized
    if (error?.response?.status === 401) {
      if (location.pathname !== '/login') {
        const store = useAuthStore();
        await store.logout();
      }
    }

    // Bad request
    else if (400 <= error?.response?.status && error?.response?.status < 500) {
      const message =
        error?.response?.data?.message ??
        'Your request could not be processed. Please adjust your input and try again.';

      Notify.create({
        message,
        icon: 'fas fa-exclamation-triangle',
        type: 'negative',
      });
    }

    // Server error
    else if (error?.response?.status >= 500) {
      Notify.create({
        icon: 'fas fa-bomb',
        message: 'An error occurred. Please try again later.',
        type: 'negative',
      });
    }

    return Promise.reject(error);
  },
);

export { client, paramFormatter };
