import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/epks',
    meta: { middlewares: [Auth, HasFeature('artist_promotion')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: ':epk',
        component: () => import('./layouts/EpkLayout.vue'),
        children: [
          {
            path: '',
            name: 'EpkGeneralView',
            component: () => import('./views/EpkGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'media',
            name: 'EpkMediaView',
            component: () => import('./views/EpkMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'EpkLogsView',
            component: () => import('./views/EpkLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
