import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/ticket-sales-report',
    meta: { middlewares: [Auth, HasFeature('booking_ticketing')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'ShowTicketSaleReportView',
        component: () => import('./views/ShowTicketSaleReportView.vue'),
        meta: { title: 'Ticket Sales Report' },
      },
    ],
  },
];
