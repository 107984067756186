import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/export',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'mails',
        name: 'ExportContactableMailView',
        component: () => import('./views/ExportContactableMailView.vue'),
        meta: { title: 'Mail export' },
      },
    ],
  },
];
