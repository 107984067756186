import { RouteRecordRaw } from 'vue-router';

import { HasFeature } from '$common/modules/feature-flags/middlewares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/calendar',
    meta: { middlewares: [HasFeature('tools_calendar')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'Calendar',
        component: () => import('./views/CalendarView.vue'),
        meta: { title: 'Calendar' },
      },
    ],
  },
];
